<template>
  <div class="notfound-style">
    <v-card 
        flat
        color="transparent" 
        max-width="800" 
        height="100%"
        class="mx-auto d-flex justify-center align-center">
        <v-card-text>
          <v-row class="px-5 d-flex flex-column flex-md-row flex-lg-row justify-md-center align-md-center">
            <v-col cols="12" md="6" lg="6" class="d-flex flex-column justify-center align-center text-center">
              <span class="text-h5 text-md-h3 text-lg-h3 text-uppercase"
                :class="[{'white--text': $store.state.corpInfo.darkTheme}, {'black--text': !$store.state.corpInfo.darkTheme}]">
                404 {{ $t('messages.notFoundMess.defaultTitle') }}
              </span>
              <span class="text-h7 text-md-h6 text-lg-h6 text-uppercase mt-3"
                :class="[{'white--text': $store.state.corpInfo.darkTheme}, {'black--text': !$store.state.corpInfo.darkTheme}]">
                {{ $t('messages.notFoundMess.defaultLegend') }}
              </span>
              <v-btn dark color="var(--color-primary)" x-large @click="$root.goHome()" class="mt-5">
                <v-icon>mdi-home</v-icon>
                <div>{{ $t('actions.goBack') }}</div>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" lg="6" class="text-center">
              <img src="../../assets/sorry.webp" class="image-ups-style"/>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'notFoundBody',
  data() {
    return {
      
    }
  }
}
</script>

<style scoped>
.notfound-style {
  height: 100% !important;
}
.image-ups-style {
  max-height: 400px;
}
@media (max-width: 800px) {
  .image-ups-style {
    max-height: 300px;
  }
}
</style>