<template>
  <div class="user-view-style" :class="[{'no-show':!$root.token}]"
    :style="{
      'background-color': $store.state.corpInfo.darkTheme?'black':'#EEEEEE'
    }">
    <div v-if="$root.token">
      <UserTransactions 
        class="pa-0"
        ref="UserTransactions"
        :currentTab="0"/>
    </div>
    <div v-else class="no-found-style">
      <NotFound/>
    </div>
  </div>
</template>

<script>
import NotFound from '../components/404-NotFound/404-body.vue'
import UserTransactions from "../components/userTickets/userTransactions.vue";

export default {
  name: "UserTransactionsView",
  components: {
    UserTransactions,
    NotFound
  },
  data() {
    return {
      currentTab: 0,
      notDataFound: false
    }
  },
  mounted() {
    this.$root.resetShoppingTime()
    this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente
    if(!this.$root.token) {
      this.notDataFound = true;
      this.$root.swalAlert("warning", this.$t("messages.TokenInvalid"));
      this.$root.showlogin = true;
      this.$root.loading = false;
      return false;
    }
  }
}
</script>

<style scoped>
.user-view-style {
  min-height: 100%;
  height: fit-content;
  overflow: auto;
}
.no-show {  
  height: 100%;
  overflow: hidden;
}
.no-found-style {
  height: 100%;
}
</style>