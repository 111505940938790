<template>
  <div>
    <v-skeleton-loader
      v-if="!items && !showNoData"
      :dark="$store.state.corpInfo.darkTheme"
      class="mx-auto"
      max-width="1190"
      type="table"
    ></v-skeleton-loader>
    <v-container v-else-if="showNoData" class="mx-auto pt-10 d-flex flex-column justify-center align-center">
      <v-icon class="pa-5" size="50px" color="grey darken-2" dark>
        mdi-cart-minus
      </v-icon>
      <span class="text-center text-h6 text-uppercase">{{ $t("messages.noShopping") }}</span>
    </v-container>
    <v-container v-else>
      <v-card-text class="pa-0 my-card-tickets-profile" 
        :style="{'background-color':$store.state.corpInfo.darkTheme?'#20202A':'#E6E6E6'}">
        <TableTransaction ref="TableTransaction" class="child-transac" :Eventos="items" :tab="0"/>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import TableTransaction from "./TableTransactions.vue";

export default {
  name: 'GeneralTransactions',
  props: ['currentTab'],
  components: {
    TableTransaction
  },
  data() {
    return {
      currentTabTemp: 0,
      items: null,
      showNoData: false
    }
  },
  watch: {
    currentTab: function(val) {
      this.currentTabTemp = val;
    }
  },
  mounted() {
    this.items = null
    this.currentTabTemp = this.currentTab;
    this.userCulturTransac();
  },
  methods: {
    userCulturTransac() {
      let self = this;      
      this.showNoData = false;

      this.$root.post("/ServiceClient/GetUserTransactions", {
        id_corp: this.$root.id_corp 
      }).then((response) => {
        let info = response.data;
        if(info.success === "TRUE") {
          this.items = info.Data;  

          setTimeout(() => {
            // if(self.currentTab === 0) 
            self.$refs.TableTransaction.loadleng();
            // else self.$refs.CulturTransac.$refs.TableTransactionEvents.loadleng();     
          }, 220);
        } else {          
          if(info.Msg == "Token no valido, favor de volverse a logear") {
            this.$root.swalAlert("warning", this.$t("messages.TokenInvalid"));
            this.$root.goLogin()
          }else{
            this.$root.swalAlert("warning", info.message);
          }
          this.showNoData = true;
        }
      })
      .catch(error => {
        this.$root.swalAlert("warning", error.message);
        this.showNoData = true;
      }).finally(() => {
        this.$root.loading = false;
      })
    }
  },
}
</script>

<style scoped>
.my-card-tickets-profile {
  margin: 0 !important;
  box-sizing: border-box;
}
.child-transac {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0px;
}
.toolbar-cultur {
  background: var(--color-primary-light);
  background: linear-gradient(
    207deg,
    var(--color-primary-light)  0%,
    var(--color-primary) 45%,
    var(--color-primary-light1) 99%
  );
  padding-left: .5vw;
}
textarea:focus,
input:focus {
  outline: none;
}
</style>