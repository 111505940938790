<template>
  <v-dialog 
    v-model="showForm"
    persistent
    max-width="800">
    <v-card>
      <LoadingA v-if="loadingBtn"/>
      <v-toolbar class="grey darken-3">
        <v-toolbar-title class="white--text">CANCELACION DE TRANSACCION</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="py-5">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <span>Este formulario solo se podra llenar una sola vez. </span>
              <span>Justifique la razon de su cancelacion, al momento de confirmar se enviara esta justificacion a XPERSONA y se sometera a revision</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-form ref="CancelTransacForm">                
                <v-textarea
                  v-model="reason"
                  outlined
                  name="input-7-4"
                  label="Cancellation reason"
                  value=""
                  :rules="[rules.required]"
                ></v-textarea>
              </v-form>
            </v-col>
          </v-row>
        </v-container>         
        
      </v-card-text>
      <v-divider inset class="mx-auto"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" outlined @click="ConfirmCancellation" :loading="loadingBtn">
          ACEPTAR
        </v-btn>
        <v-btn dark @click="ResetCancellation" color="pink accent-3" class="mr-5">
          CANCELAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingA from '../Tools/LoadingAnimation.vue';

export default {
  name: 'CancellationFormTool',
  components : {
    LoadingA
  },
  data() {
    return {
      showForm: false,
      reason: '',
      transac: null,
      loadingBtn: false,
      rules: {
        required: (v) => !!v || this.$t("messages.validation.required"),          
      },
    }
  },
  methods : {
    StartCancellation(transac) {
      this.transac = transac;
      this.showForm =  true;
    },
    ResetCancellation() {
      this.reason = ''
      this.showForm =  false;
    },
    ConfirmCancellation() {
      if(this.$refs.CancelTransacForm.validate()) {
        this.$root.confimDelay('warning', "ALERTA", "Una vez confirmado, no podrá enviar otra respuesta.", "Sí, confirmo la cancelacion").then(
          (success) => {
            let self = this;
            if(success.isConfirmed) {
              self.loadingBtn = true;
              self.$root.post("/ServiceClient/CancelTransac",{
                id_transac: self.transac.id_transac, 
                clave: self.transac.clave,
                event_config: self.transac.event_config, 
                reason: self.reason,
                time_tra: self.transac.time_tra,
                date_tra: self.transac.date_tra,
                amount: self.transac.amount
              }).then((response) => {
                  let info = response.data;
                  if(info.success == "TRUE") {
                    self.transac.cancelled = true;
                    self.$root.swalAlert('success','','Transacción cancelada')
                  } else {
                    self.$root.swalAlert('warning','','Error al cancelar transaccion')
                  }
                  self.loadingBtn = false;
                  self.ResetCancellation();
              });
            }
          },
          (error) => false
        );
      }      
    }
  }
}
</script>

<style scoped>
.text-area-style {
  border: 1px solid red;
  max-height: 400px;
}
</style>