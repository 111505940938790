<template>
  <div>
    <v-container class="pa-0 transparent" 
      style="padding: 0px !important;">
      <v-card-title style="padding-top: 8px !important;">
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="Eventos"
          fixed-header
          :search="this.$vEntorno.search"
          :headers="headers"
          :items="Eventos"
          :items-per-page="10"
          @click:row="openOnClick"
          :single-expand="singleExpand"
           class="elevation-1 pointman"
          item-key="id_transac"
          mobile-breakpoint="0">
          <template v-slot:[`item.amount`]="{ item }">
            <td class="text-center font-weight-bold text-subtitle-1">
              ${{ $root.formatPrice(item.amount) }}
            </td>            
          </template>          
          <template v-slot:[`item.proofPayment`]="{ item }">
            <td class="text-center">
              <v-btn outlined small dark color="brown darken-4" :loading="loadingPayProof && indexLoading == item.id_transac" @click="printProofPayment(item)">
                <v-icon size="20">mdi-file-check</v-icon>
              </v-btn>
            </td>            
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <td align="center">
              <v-tooltip right v-if="item.paid !== 1" color="grey darken-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip                  
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="caption text-uppercase" 
                    style="
                      font-size:10.5px !important; 
                      min-width: 80% !important; 
                      justify-content: center !important;" 
                    dark 
                    color="red accent-3">
                    {{ $t('actions.paymentStatus.notPaid') }}
                  </v-chip>
                </template>
                <span> {{ item.error ? item.error : 'Payment error' }} </span>
              </v-tooltip>
              <v-chip
                v-else
                class="caption text-uppercase" 
                style="
                  font-size:10.5px !important; 
                  min-width: 80% !important; 
                  justify-content: center !important;" 
                dark 
                color="green darken-1">
                {{ $t('actions.paymentStatus.paid') }}
              </v-chip>         
            </td>           
          </template>

          <template v-slot:expanded-item="{ headers, item }" >
            <td :colspan="headers.length" class="myTable">
              <v-skeleton-loader
                class="my-4 mx-5"
                type="card"
                height="100"
                v-if="loadingTransactions"
              ></v-skeleton-loader>

              <v-card class="my-4 mx-5" v-else>
                <v-simple-table class="myTable2 nopintman">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="subTable in headerSubTable"
                          :key="subTable.text"
                          :class="`text-${subTable.align} black accent-4 white--text`"
                        >
                          {{ subTable.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody style="display: contents !important;" class="BmyTable2">
                      <tr
                        v-for="(transac, index) in transactions"
                        :key="`ticketSubTable-${transac.id_transac}-${index}`">
                        <td class="img-sitio" align="center">
                          <v-img
                            :src="$root.pathevens + transac.img"
                            style="
                              max-width: 150px;
                              max-height: 90px;
                              margin: 5px;
                              border-radius: 5px;">
                          </v-img>
                        </td>                      
                        <td class="text-center">{{ transac.sitio }}</td>
                        <td class="text-center">{{ transac.alltickets }}</td>
                        <td class="text-center">${{ $root.formatPrice(transac.subtotal)  }}</td>
                        <td align="center">
                          <v-btn
                            color="var(--color-primary)"
                            class="mr-2"
                            small
                            @click="openTicketsTransac(transac.tickets, transac.sitio)"
                          >
                            <v-icon color="white"> mdi-eye </v-icon>
                          </v-btn>                          
                        </td>
                        <td align="center">
                          <v-btn
                            color="green"
                            v-if="item.paid === 1"
                            small
                            :loading = "loadingTickets && indexTicketLoading === transac.id_event"
                            :disabled = "loadingTickets && indexTicketLoading !== transac.id_event"
                            @click="printTickets( item, transac )" >
                            <v-badge
                              :content="transac.printed"
                              :value="transac.printed"
                              color="red"
                              overlap
                            >
                              <v-icon color="white"> mdi-printer </v-icon>
                            </v-badge>
                          </v-btn>
                          <v-btn
                            v-else
                            color="red"
                            small
                            disabled >
                            <v-icon>
                              mdi-cancel
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-container>
    <v-dialog v-model="dialogTickets" max-width="1250" scrollable>
      <v-card tile class="display-transac-card">
        <v-toolbar :dark="$store.state.corpInfo.darkTheme" color="var(--color-primary)">
          <v-toolbar-title :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
            {{ titleDialogTickets }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :dark="$store.state.corpInfo.darkTheme" @click="dialogTickets = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 100%;" class="mt-4">          
          <v-data-table
            :headers="headerTableTickets[headerSelected]"
            :items="tickets"
            :items-per-page="5"
            @click:row="openPackages"
            :single-expand="packagesExpanded"
            class="elevation-0 pointman"
            item-key="id">        
            <template v-slot:[`item.name`]="{ item }">
              <td class="text-center" style="max-width: 150px;">
                  {{ item.name[$root.getTextlang()] }}
              </td>            
            </template>
            <template v-slot:[`item.unit_price`]="{ item }">
              <td class="text-center">
                  ${{ $root.formatPrice(item.unit_price) }}
              </td>            
            </template>
            <template v-slot:expanded-item="{ headers, item }" >
              <td :colspan="headers.length" class="myTable">
                <v-card class="my-4 mx-5">
                  <v-simple-table class="myTable2 nopintman">
                    <template v-slot:default>
                      <thead>
                        <tr  v-if="$root.getTextlang() == 'ES'">
                          <template  v-for="(subTable, index4) in headerTableTickets[1]" >
                            <th
                              :class="`text-${subTable.align} black accent-4 white--text`"
                              :key="'Ticket10'+index4"
                              v-if="index4 != 2"
                            > 
                              {{ subTable.text }}
                            </th>
                          </template>
                        </tr>
                        <tr  v-else>
                          <template  v-for="(subTable, index4) in headerTableTickets[1]" >
                            <th
                              :class="`text-${subTable.align} black accent-4 white--text`"
                              :key="'Ticket10'+index4"
                            > 
                              {{ subTable.text }}
                            </th>
                          </template>
                        </tr>
                      </thead>
                      <tbody style="display: contents !important;" class="BmyTable2">
                        <tr
                          v-for="(tick, index) in ticketsc"
                          :key="`ticketSubTable-${tick.alias}-${index}`">       
                          <td class="text-center">{{ item.cantidad}}</td>      
                          <td class="text-center">{{ tick.alias }}</td>   
                          <td class="text-center" v-if="$root.getTextlang() != 'ES'">{{ tick.alias2[$root.getTextlang()] }}</td>   
                          <td class="text-center">{{ tick.event }}</td>
                          <td class="text-center">${{ $root.formatPrice(tick.price)  }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
          </v-data-table>        
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CancelTransacFormTool from './CancellationForm.vue';
export default {
  name: "TableTicket",
  props: ["Eventos", "tab"],
  components: {
    CancelTransacFormTool
  },
  data() {
    return {
      headers: [],
      expanded: [],
      singleExpand: true,
      packagesExpanded : true,
      headerSubTable: [],
      headerTableTickets: [],
      transactions: [],
      loadingTransactions: false,
      dialogTickets: false,
      event_config: 1,
      tickets: [],
      ticketsc: [],
      total: "",
      titleDialogTickets: "",
      headerSelected: 0, 
      flag: false,
      loadingTickets: false,
      loadingPayProof: false,
      indexLoading: -1,
      indexTicketLoading: -1
    };
  },
  mounted(){
    this.loadleng()
  },
  methods: {
    openOnClick (item, slot) {
      if(this.flag) return this.flag = false
      switch (item.event_config) {
        case 1:
        case 5:
          this.headerSelected = 2
          break;      
        case 2:
          this.headerSelected = 3
          break;        
        case 3:
          this.headerSelected = 0
          break;        
        case 4:
          this.headerSelected = 4          
          break;
      }
      if (!slot.isExpanded) {
        this.loadingTransactions = true;
        if (!item.transactions) {
          this.$root
            .post("/ServiceClient/GetUserTickets", {
              id_corp: this.$root.id_corp,
              id_transac: item.id_transac
            })
            .then(
              (response) => {
                let info = response.data;
                if (info.success == "TRUE") {
                  item.transactions = info.Data[0].data;
                  this.transactions = info.Data[0].data;
                }
                this.loadingTransactions = false;
              },
              (error) => {
                this.loadingTransactions = false;
              }
            );
        } else {
          this.transactions = item.transactions;
          this.loadingTransactions = false;
        }
      }
      slot.expand(!slot.isExpanded);
    },
    openPackages (item, slot) {
      if(item.ticketsc.length > 0) {
        this.ticketsc = item.ticketsc;
        slot.expand(!slot.isExpanded);
      }
    },
    openTicketsTransac (item, clave) {
      this.tickets = item;
      this.loadingTransactions = false;
      this.dialogTickets = true;
      this.titleDialogTickets = clave;
    },
    printTickets: function (item, transac) {
      if(item.paid !== 1) return this.$root.swalAlert('error','','No se pueden imprimir boletos');
      this.$root.showAlertOnPdf = false;
      this.$root.pdfCurrentTitle = this.$root.pdfTitles[1][this.$root.getTextlang()] 
      this.$root.resetPdf()
      this.indexTicketLoading = transac.id_event;
      this.loadingTickets = true;
      this.$root.post("/ServiceClient/printTickets", {
        id_transac: item.id_transac,
        id_event: transac.id_event,
        event_config: item.event_config,
        id_corp : this.$root.id_corp
      }).then((response) => {
        let info = response.data;
        if(info.success == 'TRUE'){
            this.$root.dpfdialog = true;
            this.$root.pdf(info.tikcets)
        } else {
          this.$root.swalAlert('warning','',this.$t("messages.ticketUploadError"))
        }
      }).catch(e => {
        this.$root.swalAlert('warning', this.$t("messages.ticketUploadError"), e)
      }).finally(() => {
        this.loadingTickets = false;
      })
    },
    printProofPayment(transac) {
      this.flag = true;  
      this.indexLoading = transac.id_transac;
      this.$root.showAlertOnPdf = false;
      this.$root.pdfCurrentTitle = this.$root.pdfTitles[0][this.$root.getTextlang()]
      this.$root.resetPdf()  
      this.loadingPayProof = true;
      this.$root.post("/ServiceClient/GetProofPayment",{
        id_transac: transac.id_transac,
        event_config: transac.event_config,
        id_corp : this.$root.id_corp
      }).then((response) => {
        let info = response.data;
        if(info.success === 'TRUE'){
          this.$root.proofPayment(info.info)
        } else {
          this.$root.swalAlert('warning','',this.$t("messages.ticketUploadError"))
        }
      })
      .catch(e => this.$root.swalAlert('error',this.$t("messages.ticketUploadError"), e))
      .finally(() => {
        this.loadingPayProof = false;
      }) 
    },
    loadleng(){
      this.headers= [
        {
          text: this.$t("tableHeaders.transac"),
          align: "center",
          value: "clave",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
        {
          text: this.$t("tableHeaders.dtransac"),
          align: "center",
          value: "date_tra",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
        {
          text: this.$t("tableHeaders.ttransac"),
          align: "center",
          value: "time_tra",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
        {
          text: "total",
          align: "center",
          value: "amount",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
        {
          text: this.$t("tableHeaders.status"),
          align: "center",
          value: "paid",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
        {
          text: this.$t("tableHeaders.proof"),
          align: "center",
          value: "proofPayment",
          class: "black accent-4 subtitle-2 text-uppercase white--text",
        },
      ]

      this.headerSubTable= [        
        { value: "img", class: "black accent-4", width: "10%" },
        { text: this.$t("actions.place"), align: "center" },
        { text: this.$t("actions.tickets")+'s', align: "center" },
        { text: "Subtotal", align: "center" },
        { text: this.$t("actions.SeeTickets"), align: "center" },
        { text: this.$t("actions.printTickets"), align: "center" },
      ]
      this.headerTableTickets= [
        [
          { text:  this.$t("actions.cardinfo.ticketsUp"), align: "center" , value: "name", class: "grey lighten-1 grey--text text--darken-4 text--darken-3"},
          // { text:  this.$t("actions.cardinfo.ticketsUp") + " - " + this.$root.getTextlang(), align: "center" , value: "name2", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.amount"), align: "center" , value: "cantidad", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.unitPrice") , align: "center" , value: "unit_price", class: "grey lighten-1 grey--text text--darken-4"}
        ],
        [
          { text: this.$t("actions.cardinfo.numberTickets"), align: "center" },
          { text: this.$t("actions.cardinfo.tickets"), align: "center" },
          { text: this.$t("actions.cardinfo.tickets") + " - " + this.$root.getTextlang(), align: "center" },
          { text: this.$t("actions.Events"), align: "center" },
          { text: this.$t("actions.cardinfo.unitPrice2"), align: "center" }
        ],
        [
          { text:  this.$t("actions.cardinfo.ticketsUp"), align: "center" , value: "name", class: "grey lighten-1 grey--text text--darken-4"},
          // { text:  this.$t("actions.cardinfo.ticketsUp") + " - " + this.$root.getTextlang(), align: "center" , value: "name2", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.amount"), align: "center" , value: "cantidad", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.dateEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "date_event" },
          { text: this.$t("actions.cardinfo.timeEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "time_event" },
          { text: this.$t("actions.cardinfo.section"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "section" },
          { text: this.$t("actions.cardinfo.seat"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "title" },
          { text: this.$t("actions.cardinfo.row"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "row" },
          { text: this.$t("actions.cardinfo.colum"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "col" },
          { text: this.$t("actions.cardinfo.unitPrice") , align: "center" , value: "unit_price", class: "grey lighten-1 grey--text text--darken-4" },
        ],
        [
          { text:  this.$t("actions.cardinfo.ticketsUp"), align: "center" , value: "name", class: "grey lighten-1 grey--text text--darken-4"},
          // { text:  this.$t("actions.cardinfo.ticketsUp") + " - " + this.$root.getTextlang(), align: "center" , value: "name2", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.amount"), align: "center" , value: "cantidad", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.dateEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "date_event" },
          { text: this.$t("actions.cardinfo.timeEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "time_event" },
          { text: this.$t("actions.cardinfo.section"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "section" },
          { text: this.$t("actions.cardinfo.unitPrice") , align: "center" , value: "unit_price", class: "grey lighten-1 grey--text text--darken-4"}
        ],
        [
          { text:  this.$t("actions.cardinfo.ticketsUp"), align: "center" , value: "name", class: "grey lighten-1 grey--text text--darken-4"},
          // { text:  this.$t("actions.cardinfo.ticketsUp") + " - " + this.$root.getTextlang(), align: "center" , value: "name2", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.amount"), align: "center" , value: "cantidad", class: "grey lighten-1 grey--text text--darken-4"},
          { text: this.$t("actions.cardinfo.dateEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "date_event" },
          { text: this.$t("actions.cardinfo.timeEvent"), align: "center", class: "grey lighten-1 grey--text text--darken-4 text-uppercase", value: "time_event" },
          { text: this.$t("actions.cardinfo.unitPrice") , align: "center" , value: "unit_price", class: "grey lighten-1 grey--text text--darken-4"}
        ],
      ]
    },
    cancelTransac(transac) {
      this.$refs.CancelTransacFormTool.StartCancellation(transac);
    }
  },
};
</script>

<style scoped>
.pointman{
  cursor: pointer;
}
.nopintman{
  cursor:auto;
}
.BmyTable2 tr:nth-child(even) {background-color: #e2efff;}

.img-sitio {
  max-width: 120px;
}
.myTable {
  max-width: 80vw;
}
.v-data-table:hover {
  opacity: 1;
}

@media (max-width: 600px) {
  .myTable {
    max-width: 81vw;
  }
}
@media (max-width: 530px) {
  .myTable {
    max-width: 80vw;
  }
}
@media (max-width: 415px) {
  .myTable {
    max-width: 75vw;
  }
}
@media (max-width: 410px) {
  .myTable {
    max-width: 72vw;
  }
}
</style>